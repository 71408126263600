var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "feature-drawer" },
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { right: "", absolute: "", temporary: "" },
          model: {
            value: _vm.openDrawer,
            callback: function ($$v) {
              _vm.openDrawer = $$v
            },
            expression: "openDrawer",
          },
        },
        [
          _c("div", { staticClass: "drawer-wrapper" }, [
            _c("div", { staticClass: "toolbar" }, [
              _c("div", { staticClass: "drawer-title" }, [
                _vm._v("Product Feature"),
              ]),
            ]),
            _c("div", { staticClass: "drawer-content" }, [
              _c("div", { staticClass: "drawer-left-box" }, [
                _c("div", { staticClass: "drawer-top-content" }, [
                  _c("div", { staticClass: "drawer-feature-content" }, [
                    _c("div", { staticClass: "text-area-style" }, [
                      _vm._v(_vm._s(_vm.feature.feature)),
                    ]),
                    _c("div", [
                      _c("h6", { staticStyle: { color: "#979DA5" } }, [
                        _vm._v(_vm._s(_vm.feature.description)),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "iteration-progress" }, [
                  _c("div", { staticClass: "drawer-itr-name" }, [
                    _c("span", [_vm._v("Iteration:")]),
                    _c("img", {
                      attrs: { src: "/img/dashboard/iteration1.svg" },
                    }),
                    _c("span", [_vm._v("Iteration 1")]),
                  ]),
                  _c("div", { staticClass: "progress-bar-div" }, [
                    _c("div", { staticClass: "progress-title" }, [
                      _vm._v("Progress:"),
                    ]),
                    _c("div", { staticClass: "progress-bar" }),
                    _c("div", { staticClass: "progress-percentage" }, [
                      _vm._v("0%"),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "div-drawer-tasks" },
                  [
                    _c("div", { staticClass: "drawer-task-count" }, [
                      _c("img", {
                        attrs: { src: "/img/dashboard/sprint-icon.svg" },
                      }),
                      _c("span", [
                        _vm._v("Tasks (" + _vm._s(_vm.tasks.length) + ")"),
                      ]),
                    ]),
                    _vm._l(_vm.tasks, function (task) {
                      return _c("div", { key: task.id }, [
                        _c("div", { staticClass: "task-div" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(task.task) +
                              "\n                "
                          ),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
                _c("div", { staticClass: "drawer-bottom-content" }, [
                  _c("div", { staticClass: "attachment-title" }, [
                    _c("div", { staticClass: "attach-file-count" }, [
                      _c("img", {
                        staticStyle: { "margin-right": "4px" },
                        attrs: { src: "/img/dashboard/attach.svg" },
                      }),
                      _vm._v(
                        "\n                ATTACHMENTS (" +
                          _vm._s(_vm.attachmentCount) +
                          ")\n              "
                      ),
                    ]),
                    _c("div", { staticClass: "attach-file" }, [
                      _vm._v("+ Attach File"),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "drawer-uploaded-doc" },
                    _vm._l(_vm.feature.attachments, function (attachment) {
                      return _c(
                        "div",
                        {
                          key: attachment.id,
                          staticClass: "uploaded-image-cover",
                        },
                        [
                          _c("div", {
                            staticClass: "banner-position",
                            style: {
                              backgroundImage:
                                "url(" +
                                (_vm.$imageUrl + attachment.file_name) +
                                ")",
                            },
                          }),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "drawer-right-box" },
                [_c("FeatureActivity")],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }