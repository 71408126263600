<template>
  <div>
    <VuePerfectScrollbar class="perfect-scroll-area" :settings="settings">
      <div v-if="iterations.length > 0" class="space-wrapper" id="it-cont">
        <div
          class="iteration-card"
          v-for="(iteration, index) in iterations"
          :key="index"
        >
          <div class="iteration-info">
            <div class="iteration-name">
              <img :src="`/img/dashboard/iteration${index + 1}.svg`" />
              Iteration {{ index + 1 }} -
              <span>{{ `${iteration.name}’s` }}</span>
            </div>
            <div class="iteration-duration">
              {{ project.iteration_cycle }}wks
            </div>
          </div>
          <div class="divider-line"></div>
          <VuePerfectScrollbar class="perfect-scroll-area" :settings="settings">
            <div class="features-wrapper">
              <div
                class="feature-card"
                v-for="(feature, k) in iterations['features']"
                v-if="feature.iteration_id == iteration.id"
                :key="k"
              >
                <!-- Remove function to click a feature for now, need to rework some features -->
                <!-- @click="handleDrawer(feature, iteration)" -->
                <div>{{ feature.feature }}</div>
                <div class="feature-icons">
                  <div class="feature-chat">
                    <img src="/img/dashboard/message-icon.svg" /> 0
                  </div>
                  <div class="number-of-sprint">
                    <img src="/img/dashboard/sprint-icon.svg" />
                    {{ returnTaskLenght(feature.tasks) }}
                  </div>
                  <div class="feature-upload">
                    <img src="/img/dashboard/attach.svg" /> 8
                  </div>
                </div>
              </div>
            </div>
          </VuePerfectScrollbar>
        </div>
      </div>
    </VuePerfectScrollbar>
    <FeatureDrawer
      v-bind:tasks="featureTask"
      v-bind:feature="featureDetails"
      ref="drawer"
    />
  </div>
</template>

<script>
import FeatureDrawer from "./featureDrawer";
import FeatureCard from "@/components/workspace/featureComponent";
import { mapActions, mapState } from "vuex";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  props: {},
  components: {
    FeatureDrawer,
    FeatureCard,
    VuePerfectScrollbar,
  },
  data: () => ({
    fetureOne: "Dummy feature",
    featureDetails: {},
    iterations: [(features) => []],
    tasks: [],
    featureTask: [],
    settings: {
      maxScrollbarLength: 60,
    },
  }),

  created() {
    this.getProjectDoc();
  },
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),

    handleDrawer(feature, iteration) {
      this.featureDetails = feature;
      this.featureTask = [];
      this.tasks.forEach((element) => {
        if (element.feature_id == feature.id) {
          this.featureTask.push(element);
        }
      });
      this.featureDetails["tasks"] = this.featureTask;

      this.$refs.drawer.toggleDrawer();
    },

    getProjectDoc() {
      let { documents } = this.projectObject;
      this.iterations = documents.iterations;
      this.iterations["features"] = documents.features;
      this.tasks = documents.tasks;
    },

    returnTaskLenght(tasks) {
      return tasks.length;
    },
  },
  computed: {
    ...mapState("clientDashboard", ["clientProjectDoc", "clientProject"]),
    projectObject() {
      return this.clientProjectDoc;
    },
    project() {
      return this.clientProject;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.divider-line {
  margin-top: 16px;
}
.features-wrapper {
  height: calc(100vh - 205px);
}

.feature-upload, .feature-chat
{
  display: none;
}
</style>
