import Axios from "axios";
let baseURL = process.env.VUE_APP_BACKEND_API;
// let baseURL = `https://grupa-api-pre-live.herokuapp.com/api`;
const user = JSON.parse(localStorage.getItem("grupa_user"));

const axios = Axios.create({
  baseURL,
  timeout: 100000,
});

axios.interceptors.request.use(
  function (config) {
    const user = JSON.parse(localStorage.getItem("grupa_user"));
    config.headers.Authorization = `Bearer ${user.token}`;
    config.headers.common["Access-Control-Allow-Origin"] = "*";
    config.headers.common["Content-Type"] = `application/json`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const getClientProject = (id) => {
  return axios.get(`${baseURL}/client/project/` + id);
};
// Client Invite Collaborator to product
export const inviteCollaborator = (payload) => {
  return axios.post(`${baseURL}/client/project/collaborator`, payload);
};

// Client plan ahead
export const planAheadSubcription = (payload) => {
  return axios.post(`${baseURL}/client/project/plan-ahead`, payload);
};

//
export const clientMarkSprintAsCompleted = (payload) => {
  return axios.post(
    `${baseURL}/client/project/sprint/mark_as_completed`,
    payload
  );
};

export const addFounder = (payload) => {
  return axios.post(`${baseURL}/client/add/founders`, payload);
};

export const removeFounder = (payload) => {
  return axios.post(`${baseURL}/client/remove/founders`, payload);
};

export const updateStartUp = (payload) => {
  return axios.post(`${baseURL}/client/update/startup`, payload);
};

export const updateProductUp = (payload) => {
  return axios.post(`${baseURL}/client/update/product`, payload);
};

export const updateInvestor = (payload) => {
  return axios.post(`${baseURL}/client/update/investor`, payload);
};

export const updatePreference = (payload) => {
  return axios.post(`${baseURL}/client/update/preference`, payload);
};

export const onboardingUserData = (payload) => {
  return axios.post(`${baseURL}/onboarding/get/user`, payload);
};

export const updateIndustry = (payload) => {
  return axios.post(`${baseURL}/client/update/industry`, payload);
};

export const updatePlatform = (payload) => {
  return axios.post(`${baseURL}/client/update/platform`, payload);
};

//


export const FounderMeetingChecher = (payload) => {
  return axios.get(
    `${baseURL}/client/project/meeting/checker/${payload.project_id}/${payload.client_id}`
  );
};

//

export const RequestEngineerChange = (payload) => {
  return axios.post(`${baseURL}/admin/request/engineer/change`, payload);
};

export const RequestEngineerConnect = (payload) => {
  return axios.post(`${baseURL}/admin/request/engineer/connect`, payload);
};

export const markSprintAndRateTeam = (payload) => {
  return axios.post(
    `${baseURL}/client/project/sprint/save-customer-satisfaction`,
    payload
  );
};

// get Reports
export const getReportBySprintId = (sprint_id) => {
  return axios.get(`${baseURL}/dev/project/get-report-sprint/${sprint_id}`);
};

export const clientPaymentCards = () => {
  return axios.get(`${baseURL}/client/wallet/cards`);
};

export const editDevImage = (payload) => {
  return axios.post(`${baseURL}/dev/settings/profile_picture`, payload);
};
export const clientApplyVoucher = (payload, project_id) => {
  return axios.post(
    `${baseURL}/client/project/evaluation-discount/${project_id}`,
    payload
  );
};

export const getDiscountObject = (id) => {
  return axios.get(`${baseURL}/dev/get-discount-code/${id}`);
};

//

//

export const getRoles = (id) => {
  return axios.get(`${baseURL}/onboarding/client/roles`);
};

export const getPlatform = (id) => {
  return axios.get(`${baseURL}/onboarding/client/platform`);
};

export const getHearAboutUs = (id) => {
  return axios.get(`${baseURL}/onboarding/client/hear_about_us`);
};

export const addHearAboutUs = (payload) => {
  return axios.post(`${baseURL}/onboarding/client/add/hear_about_us`, payload);
};

export const getIndustry = (id) => {
  return axios.get(`${baseURL}/onboarding/client/industry`);
};

export const updateTechStack = (payload) => {
  return axios.post(`${baseURL}/client/update/tech_stack`, payload);
};

export const updateLookingFor = (payload) => {
  return axios.post(`${baseURL}/client/update/looking_for`, payload);
};

//updateTechStack

export const clientSaveTeam = (payload) => {
  return axios.post(`${baseURL}/onboarding/client/save_team`, payload);
};

export const clientCreateEngineerTeam = (payload) => {
  return axios.post(`${baseURL}/onboarding/client/eng_make_team`, payload);
};

export const createProject = (payload, type) => {
  //console.log(payload);
  // alert(payload);
  const method = type ? "put" : "post";
  return axios[method](`${baseURL}/onboarding/client/new_startup`, payload);
};

export const getProject = (payload, type) => {
  //console.log(payload);
  // alert(payload);
  const method = type ? "put" : "post";
  return axios.post(`${baseURL}/onboarding/client/get_project`, payload);
};

// export const createProject = (payload, type) => {
//   const method = type ? "put" : "post";
//   if (method) {
//     return axios.put(
//       `${baseURL}/onboarding/client/new_startup/${payload.project_id}`,
//       payload
//     );
//   }
//   return axios.post(`${baseURL}/onboarding/client/new_startup`, payload);
// };

export const updateProject = (payload) => {
  const { project_id } = payload;
  return axios.post(
    `${baseURL}/onboarding/client/project/update/${project_id}`,
    payload
  );
};

export const updateProjectCompensation = (payload, project_id) => {
  //const { project_id } = payload;
  return axios.post(
    `${baseURL}/admin/project/update_compensation_id/${project_id}`,
    payload
  );
};
