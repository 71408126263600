var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "feature-card", on: { click: _vm.handleDrawer } },
    [
      _c("div", [_vm._v(_vm._s(_vm.feature))]),
      _c("div", { staticClass: "feature-icons" }, [
        _c("div", { staticClass: "feature-chat" }, [
          _c("img", { attrs: { src: "/img/dashboard/message-icon.svg" } }),
          _vm._v(" " + _vm._s(_vm.featureDetails.tasks) + "\n    "),
        ]),
        _c("div", { staticClass: "number-of-sprint" }, [
          _c("img", { attrs: { src: "/img/dashboard/sprint-icon.svg" } }),
          _vm._v(" " + _vm._s(_vm.featureDetails.chats) + "\n    "),
        ]),
        _c("div", { staticClass: "feature-upload" }, [
          _c("img", { attrs: { src: "/img/dashboard/attach.svg" } }),
          _vm._v(" " + _vm._s(_vm.featureDetails.files) + "\n    "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }