var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "drawer-activity-wrapper" },
      [
        _c("div", { staticClass: "drawer-activity-title" }, [
          _vm._v("Activity"),
        ]),
        _c("div", { staticClass: "current-user-box" }, [
          _c("div", { staticClass: "current-username-div" }, [
            _c("div", { staticClass: "user-name-icon" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.abbrevateName(_vm.user.name).toUpperCase()) +
                  "\n        "
              ),
            ]),
            _c("div", { staticClass: "connector-line" }),
          ]),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.message,
                expression: "form.message",
              },
            ],
            staticClass: "text-area-style chat-textarea",
            attrs: { placeholder: "Drop a comment" },
            domProps: { value: _vm.form.message },
            on: {
              keydown: function ($event) {
                return _vm.keydownComment($event)
              },
              blur: function ($event) {
                return _vm.sendComment($event)
              },
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "message", $event.target.value)
              },
            },
          }),
        ]),
        _vm._l(_vm.featureComments, function (comment, j) {
          return _c(
            "div",
            { key: j, staticClass: "comment-list-div" },
            [
              _vm._l(_vm.teamMembers, function (member, i) {
                return member.id == comment.team_user
                  ? _c(
                      "div",
                      { key: i, staticClass: "username-profile" },
                      [
                        _c("v-avatar", {
                          staticClass: "profile_bg",
                          style: {
                            backgroundImage:
                              "url(" +
                              (_vm.$imageUrl + member.profile_picture ||
                                _vm.test) +
                              ")",
                          },
                          attrs: { size: 16, color: "grey lighten-4" },
                        }),
                        _c("div", { staticClass: "commentor-name" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.shortenName(member.name)) +
                              " •\n          "
                          ),
                          comment.date_timezone
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$moment(comment.date_timezone).fromNow()
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ],
                      1
                    )
                  : _vm._e()
              }),
              _c("div", { staticClass: "msg-connector" }, [
                _c("div", { staticClass: "message-connect" }),
                _c("div", { staticClass: "messages-box-div" }, [
                  _vm._v(
                    "\n          " + _vm._s(comment.message) + "\n        "
                  ),
                ]),
              ]),
            ],
            2
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }