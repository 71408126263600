<template>
  <div>
    <div class="drawer-activity-wrapper">
      <div class="drawer-activity-title">Activity</div>
      <div class="current-user-box">
        <div class="current-username-div">
          <div class="user-name-icon">
            {{ abbrevateName(user.name).toUpperCase() }}
          </div>
          <div class="connector-line"></div>
        </div>
        <textarea
          placeholder="Drop a comment"
          class="text-area-style chat-textarea"
          v-model="form.message"
          @keydown="keydownComment($event)"
          @blur="sendComment($event)"
        >
        </textarea>
      </div>
      <div
        class="comment-list-div"
        v-for="(comment, j) in featureComments"
        :key="j"
      >
        <div
          class="username-profile"
          v-for="(member, i) in teamMembers"
          :key="i"
          v-if="member.id == comment.team_user"
        >
          <v-avatar
            :size="16"
            color="grey lighten-4"
            class="profile_bg"
            :style="{
              backgroundImage: `url(${
                $imageUrl + member.profile_picture || test
              })`,
            }"
          ></v-avatar>
          <div class="commentor-name">
            {{ shortenName(member.name) }} •
            <span v-if="comment.date_timezone">{{
              $moment(comment.date_timezone).fromNow()
            }}</span>
          </div>
        </div>
        <div class="msg-connector">
          <div class="message-connect"></div>
          <div class="messages-box-div">
            {{ comment.message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { createFeatureComment } from "@/api/team";
import shortName from "@/mixins/shortName";
import appMixin from "@/mixins/appMixin";

export default {
  props: {
    featureId: Number,
  },
  components: {},
  mixins: [shortName, appMixin],
  data: () => ({
    form: {
      message: "",
    },
    profile: "/img/onboarding/company/profile.jpeg",
  }),

  created() {},
  methods: {
    ...mapActions("devDashboard", ["updateFeatureComments"]),

    keydownComment(event) {
      if (event.key != "Enter") {
        return;
      }
      event.target.blur();
    },
    sendComment() {
      let { message } = this.form;
      if (message) {
        let payload = {
          feature_id: this.featureId,
          message,
          date_timezone: this.currentDate(),
        };
        createFeatureComment(payload)
          .then((resp) => {
            this.updateFeatureComments(resp.data.data);
            this.form.message = "";
          })
          .catch((error) => {
            this.$developConsole(error, "error resp...");
          });
      }
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser"]),
    ...mapState("devDashboard", ["featureComments", "teamInfo"]),
    teamMembers() {
      return this.teamInfo.team.members;
    },
    user() {
      return this.currentUser;
    },
  },
};
</script>
<style scoped>
.comment-list-div {
}
.username-profile {
  display: flex;
}
.commentor-name {
  margin-left: 8px;
  font-size: 14px;
  line-height: 130%;
  color: #000000;
}
.commentor-name span {
  color: #979da5;
}
.msg-connector {
  display: flex;
}
.message-connect {
  background: #e4e5e7;
  border-radius: 3px;
  width: 1px;
  min-height: 22px;
  margin-left: 8px;
  margin-top: 3px;
}
.messages-box-div {
  text-align: left;
  background: #ffffff;
  border-radius: 4px;
  margin-left: 16px;
  padding: 12px;
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
  width: 508px;
  margin-top: 8px;
  margin-bottom: 24px;
}
</style>
