var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Toolbar", { attrs: { stageTitle: "Roadmap" } }),
      _vm.project == null
        ? _c("Loader")
        : _c("div", [
            _c("div", { staticClass: "page-container" }, [
              _c("div", { staticClass: "itr-navigation" }, [
                _c("div", { staticClass: "itr-toggle" }, [
                  _c(
                    "div",
                    {
                      staticClass: "iteration-btn",
                      class: {
                        "active-btn": _vm.isIterationSprintToggle == false,
                      },
                      on: {
                        click: function ($event) {
                          _vm.isIterationSprintToggle = false
                        },
                      },
                    },
                    [_vm._v("\n            Iterations\n          ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "sprint-btn",
                      class: {
                        "active-btn": _vm.isIterationSprintToggle == true,
                      },
                      on: {
                        click: function ($event) {
                          _vm.isIterationSprintToggle = true
                        },
                      },
                    },
                    [_vm._v("\n            Sprints\n          ")]
                  ),
                ]),
                _c("div", { staticClass: "action-div" }, [
                  !_vm.isIterationSprintToggle
                    ? _c("div", { staticClass: "itr-time-line" }, [
                        _c("img", {
                          attrs: {
                            src: "/img/sprint/question-mark-circle.svg",
                            alt: "",
                          },
                        }),
                        _c("span", [
                          _vm._v(
                            "Timeline:\n              " +
                              _vm._s(
                                _vm.iterations.length *
                                  _vm.project.project.iteration_cycle
                              ) +
                              "\n              weeks"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.isIterationSprintToggle
                    ? _c("div", { staticClass: "iteration-description" }, [
                        _c("img", {
                          attrs: {
                            src:
                              "/img/dashboard/iteration" +
                              _vm.currentIteration.it_number +
                              ".svg",
                          },
                        }),
                        _vm._v(
                          "\n            Iteration " +
                            _vm._s(_vm.currentIteration.it_number) +
                            " -\n            "
                        ),
                        _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.currentIteration.name + "’s") + " "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  !_vm.project.project.accept_roadmap
                    ? _c(
                        "div",
                        {
                          staticClass: "share-roadmap-btn",
                          on: {
                            click: function ($event) {
                              _vm.openDialog = true
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: { "margin-right": "8px" },
                            attrs: { src: "/img/dashboard/mark-icon.svg" },
                          }),
                          _vm._v("\n            Approve Roadmap\n          "),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "roadmap-wrapper" },
                [
                  false
                    ? _c("div", { staticClass: "empty-roadmap" }, [
                        _c("div", [
                          _c("img", {
                            attrs: { src: "/img/dashboard/empty-roadmap.svg" },
                          }),
                        ]),
                        _c("div", { staticClass: "empty-state" }, [
                          _vm._v("There’s nothing here yet."),
                        ]),
                        _c("div", { staticClass: "roadmapping-info" }, [
                          _vm._v(
                            "\n            Start by putting the most important Product Features or User\n            Stories in Iteration 1\n          "
                          ),
                        ]),
                        _c("button", { staticClass: "grupa-blue-btn" }, [
                          _vm._v("Get Started"),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.isIterationSprintToggle
                    ? _c("ClientSprint")
                    : _c("ClientIteration"),
                ],
                1
              ),
            ]),
          ]),
      _vm.openDialog
        ? _c(
            "DialogComponent",
            _vm._b(
              {
                attrs: { dialogTitle: "Approve Roadmap" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "dialog-footer",
                      fn: function () {
                        return [
                          _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "grupa-grey-btn",
                                staticStyle: { "margin-right": "24px" },
                                on: { click: _vm.closeDialog },
                              },
                              [_vm._v("\n          No, Go back\n        ")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "grupa-blue-btn",
                                on: { click: _vm.hanldeAcceptRoadMap },
                              },
                              [
                                _vm._v("\n          Yes, Approve\n          "),
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.spinner,
                                      expression: "spinner",
                                    },
                                  ],
                                  staticClass: "button-spinner",
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: { src: "/img/lightbox/preloader.gif" },
                                }),
                              ]
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2785867483
                ),
              },
              "DialogComponent",
              { closeDialog: _vm.closeDialog },
              false
            ),
            [
              _c("div", { attrs: { id: "main-children" } }, [
                _c("div", { staticClass: "share-roadmap-msg" }, [
                  _vm._v(
                    "\n        You are about to confirm that this roadmap accurately captures your\n        product idea, and that you agree with the product development path as\n        outlined.\n      "
                  ),
                ]),
                _c("div", { staticClass: "share-roadmap-prompt" }, [
                  _vm._v("Do you wish to continue?"),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.successMsg ? _c("AlertSuccess", { attrs: { time: "7" } }) : _vm._e(),
      _vm.errorMsg ? _c("AlertError", { attrs: { time: "7" } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }