<template>
  <div>
    <VuePerfectScrollbar class="perfect-scroll-area" :settings="settings">
      <div class="sprint-container">
        <div class="sprint-wrapper">
          <div
            class="sprint-card-div"
            v-for="(sprint, j) in sprints"
            :key="j"
            v-if="sprint.iteration_id == currentIteration.id"
          >
            <div class="sprint-info">
              <div class="sprint-name">Sprint {{ sprint.sprint }}</div>
              <div style="display: flex">
                <div>
                  <img
                    v-if="sprint.status < 1"
                    :src="imgLink + 'clock-not-started.svg'"
                  />
                  <img
                    v-if="sprint.status == 1"
                    :src="imgLink + 'clock-ongoing.svg'"
                  />
                  <img
                    v-if="sprint.status == 2"
                    :src="imgLink + 'clock-done.svg'"
                  />
                </div>
                <div v-if="sprint.start_date == null" class="sprint-due-date">
                  Not Started
                </div>
                <div
                  v-if="sprint.status <= 1 && sprint.start_date != null"
                  class="sprint-due-date"
                  :style="sprint.status == 1 ? 'color: #54CF94;' : ''"
                >
                  {{ $moment(sprint.start_date).format("Do MMM") }}-
                  {{ addDaysFromDate(sprint.start_date, 4) }}
                </div>
                <div
                  v-if="sprint.status == 2"
                  class="sprint-due-date"
                  style="color: #1b1e22"
                >
                  Completed
                </div>
              </div>
            </div>
            <div v-if="sprint.status > 0">
              <!--  -->
              <div
                v-if="sprint.status == 1"
                @click="openMarkModal(sprint)"
                class="mark-as-completed-btn"
                :class="{
                  'completed-mark': percentageTasksDone(currentTasks) == 100,
                }"
              >
                Mark Sprint as Complete 
              </div>
              <div
                class="sprint-progress-div"
                :class="{ 'completed-sprint-border': sprint.status == 2 }"
              >
                <div class="flex-progress">
                  <div class="sprint-progress-wrap">
                    <div
                      class="sprint-progress-line"
                      :style="
                        sprint.status == 1
                          ? `width:${percentageTasksDone(currentTasks)}%`
                          : ''
                      "
                      :class="{ 'completed-progress-line': sprint.status == 2 }"
                    ></div>
                  </div>
                  <div class="sprint-percent">
                    {{
                      sprint.status == 1
                        ? percentageTasksDone(currentTasks)
                        : "100"
                    }}%
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="sprint-task-divider"></div>
            <VuePerfectScrollbar
              class="perfect-scroll-area"
              :settings="settings"
            >
              <div class="scroll-sprints-div">
                <div
                  v-for="(task, k) in tasks"
                  :key="k"
                  v-if="task.sprint_id == sprint.id"
                  class="task-white-card"
                  @click="handleDrawer(task, sprint)"
                >
                  <div>
                    {{ task.task }}
                  </div>
                  <div class="task-assig-icons">
                    <div style="display: flex">
                      <img
                        v-if="task.feature_id == null"
                        src="/img/dashboard/task/unlinked-task.svg"
                      />
                      <img
                        v-if="task.feature_id != null"
                        src="/img/dashboard/task/link-task.svg"
                      />

                      <div
                        class="profile-icon"
                        v-if="task.teams && task.teams.length > 0"
                      >
                        <v-avatar
                          v-for="(member, i) in teamMembers"
                          :key="i"
                          v-if="member.id == task.teams[0].dev_id"
                          :size="32"
                          color="grey lighten-4"
                          class="profile_bg"
                          :style="{
                            backgroundImage: `url(${
                              $imageUrl + member.profile_picture || test
                            })`,
                          }"
                        ></v-avatar>
                      </div>
                      <img v-else src="/img/dashboard/task/unassign-task.svg" />
                    </div>
                    <div>
                      <img
                        v-if="task.task_status_id == 5"
                        src="/img/sprint/task-completed.svg"
                      />
                      <div v-else>
                        <img
                          class="img-task-category"
                          v-if="task.teams && task.teams.length > 0"
                          :src="
                            imgLink1 +
                            taskStage(task.teams[0].task_type_id) +
                            '.svg'
                          "
                        />

                        <img v-else src="/img/dashboard/task/task-status.svg" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </VuePerfectScrollbar>
          </div>
        </div>
      </div>
    </VuePerfectScrollbar>
    <!-- <ViewSprintDialog ref="drawer" v-bind="{ selectedTask, selectedSprint }" /> -->
    <MarkSprintAsCompleted
      v-if="isMarkAsCompleted"
      v-bind:markAsCompleted="handleMarkAsCompleted"
      v-bind="{ closeDialog, spinner }"
    />
    <RateYourTeam
      v-if="isRatingModal"
      v-bind="{ closeDialog, sprintToMark, handleMarkAsCompleted, spinner }"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  clientMarkSprintAsCompleted,
  markSprintAndRateTeam,
} from "@/api/client";
import appMixin from "@/mixins/appMixin";
import computeTask from "@/mixins/computeTask";
// import ViewSprintDialog from "../../DevDashboard/products/roadmap/viewSprintDialog";
import MarkSprintAsCompleted from "./markedAsCompletedModal";
import RateYourTeam from "./rateTeamModal";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  mixins: [appMixin, computeTask],
  props: {},
  components: {
    // ViewSprintDialog,
    MarkSprintAsCompleted,
    RateYourTeam,
    VuePerfectScrollbar,
  },

  data: () => ({
    isRatingModal: false,
    isMarkAsCompleted: false,
    openDialog: false,
    currSection: null,
    newTasks: {},
    currentTask: 0,
    currentSprint: 0,
    isAssignTask: false,
    isLinkTask: false,
    isSprintStatus: false,
    newTask: {},
    Workflow: [
      { name: "Design", img: "workflow1.svg" },
      { name: "Development", img: "workflow2.svg" },
      { name: "QA Tests", img: "workflow3.svg" },
      { name: "Complete", img: "workflow4.svg" },
    ],
    imgLink: "/img/sprint/",
    test: "/img/profile.svg",
    setOpen: false,
    selectedTask: {},
    selectedSprint: {},
    imgLink1: "/img/dashboard/task/",
    dropPlaceholderOptions: {
      className: "drop-preview",
      animationDuration: "150",
      showOnTop: true,
    },
    taskMove: 0,
    isIteration: true,
    spinner: false,
    sprintToMark: null,
    settings: {
      maxScrollbarLength: 60,
    },
  }),

  created() {
    this.generateNewTasks();
    this.generateNewTasksFeature();
  },
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    ...mapActions("clientDashboard", ["updateClientProjectDoc"]),
    showTaskPrompt(event, taskId, SprintId) {
      event.stopPropagation();
      this.currentTask = taskId;
      this.currentSprint = SprintId;
      this.isLinkTask = true;
      this.isAssignTask = false;
    },
    handleAssignTask(event, taskId, SprintId) {
      event.stopPropagation();
      this.currentTask = taskId;
      this.currentSprint = SprintId;
      this.isAssignTask = true;
      this.isLinkTask = false;
    },
    openSprintStatus(event, sprintId) {
      event.stopPropagation();
      this.currentSprint = sprintId;
      this.isSprintStatus = true;
      this.isAssignTask = false;
      this.isLinkTask = false;
    },
    generateNewTasks() {
      let obj = {};
      let sprints = this.sprints.filter(
        (sprt) => sprt.iteration_id == this.currentIteration.id
      );
      sprints.map((sprint) => {
        obj[`task${sprint.id}`] = "";
      });
      this.newTask = obj;
    },
    generateNewTasksFeature() {
      let obj = {};
      let features = this.features.filter(
        (feature) => feature.iteration_id == this.currentIteration.id
      );
      features.map((feature) => {
        obj[`task${feature.id}`] = "";
      });
      this.newTasks = obj;
    },

    keydownTask(event) {
      if (event.key != "Enter") {
        return;
      }
      event.target.blur();
    },
    closeDialog() {
      this.isMarkAsCompleted = false;
      this.isRatingModal = false;
    },

    handleDrawer(task, sprint) {
      this.selectedTask = JSON.parse(JSON.stringify(task));
      this.selectedSprint = sprint;
      // this.$refs.drawer.toggleDrawer();
    },
    calculateLinkedTask(featureId) {
      let totalTasks = this.tasks.filter(
        (task) => task.feature_id == featureId
      );
      let linkedTasks = totalTasks.filter((task) => task.sprint_id !== null);
      return `${linkedTasks.length}/${totalTasks.length}`;
    },
    markAsCompleted(payload) {
      markSprintAsCompleted(payload)
        .then((response) => {
          if (response.data.status == 1) {
            // alert("Testing");
            let sprintObj = this.project.documents.sprints.findIndex(
              (eachSprint) => eachSprint.id === sprint_no
            );
            this.project.documents.sprints[sprintObj] =
              response.data.data.sprint;
            this.successMessage = true;
            this.successText = "Task Mark AS Completed";
            this.$developConsole("Sprint Object to update", sprintObj);
          } else if (response.data.status == 0) {
            this.errorMessage = true;
            this.errorText = response.data.msg;
          }
        })
        .catch((error) => {
          this.$developConsole("Error 405", "An error ......");
        });
    },
    openMarkModal(sprint) {
      if (this.percentageTasksDone(this.currentTasks) == 100) {
        this.isRatingModal = true;
        this.sprintToMark = sprint;
      }
    },
    handleMarkAsCompleted(payload) {
      this.spinner = true;
      const { id } = this.sprintToMark;
      payload.sprint_id = id;
      payload.iteration_id = this.currentIteration.id;
      // console.log(payload, "Payload");
      clientMarkSprintAsCompleted(payload)
        .then((response) => {
          this.isRatingModal = false;
          //alert("Sprint marked as completed")
          this.setSuccessMsg("Sprint Marked as Completed");
          this.updateClientProjectDoc(response.data.data);
        })
        .catch((err) => {})
        .finally(() => {
          this.spinner = false;
        });
    },
  },
  computed: {
    ...mapState("clientDashboard", [
      "teamInfo",
      "clientProjectDoc",
      "clientProject",
    ]),
    projectObj() {
      return this.clientProject;
    },
    currentIteration() {
      return this.clientProjectDoc.documents.iterations.find(
        (itr) => itr.id == this.projectObj.current_iteration
      );
    },
    features() {
      return this.clientProjectDoc.documents.features;
    },
    sprints() {
      return this.clientProjectDoc.documents.sprints;
    },
    ongoingSprint() {
      return this.clientProjectDoc.documents.sprints.find(
        (sprint) => sprint.status == 1
      );
    },
    tasks() {
      return this.clientProjectDoc.documents.tasks;
    },
    currentTasks() {
      if (this.ongoingSprint != undefined) {
        return this.clientProjectDoc.documents.tasks.filter(
          (task) => task.sprint_id == this.ongoingSprint.id
        );
      }
    },
    teamMembers() {
      //   return this.teamInfo.team.members;
      return [];
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.sprint-planning {
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 48px;
}
.sprint-info-list {
  font-size: 18px;
  line-height: 130%;
  color: #979da5;
  margin-top: 16px;
  text-align: center;
  font-weight: bold;
}
.sprint-info-list span {
  color: #0781f2;
}
.sprint-container {
  display: flex;
}
.iteartion-toggle {
  background: #ffffff;
  border-top: 1px solid #e4e5e7;
  border-bottom: 1px solid #e4e5e7;
  padding: 24px 24px 24px 12px;
  height: 72px;
}
.selected-iteration {
  background: #ffffff;
  /* width: 396px; */
}
.selected-iteration-name {
  padding: 24px 36px 24px 32px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e4e5e7;
  border-bottom: 1px solid #e4e5e7;
}
.selected-itr-title {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 4px;
}
.minimize-icon {
  margin-left: 128px;
}
.itr-features-div {
  padding: 0px 32px 8px 32px;
  max-height: 540px;
  overflow: hidden;
  overflow-y: scroll;
}
.tem-feature-header {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  width: 252px;
}
.feature-card-div {
  width: 332px;
}
.unassigned-task-div {
  margin-top: 8px;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.feature-expand-content {
  background: #f7f7f8;
  padding: 16px 16px 24px;
}
.unassigned-task-card {
  padding: 12px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: move;
}
.add-new-feature-task textarea {
  background: #ffffff;
  border-radius: 4px;
  padding: 12px;
}
.sprint-wrapper {
  padding: 32px;
  display: flex;
  padding-right: 48px;
  /* min-height: 619px; */
  /* Remove later */
  /* overflow-x: scroll; */
}
.sprint-wrapper:hover {
}
.sprint-card-div {
  margin-right: 32px;
}
.sprint-info {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.sprint-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.sprint-due-date {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 4px;
  cursor: pointer;
}
.add-sprint-task {
  margin-top: 16px;
  background: #ffffff;
  border-radius: 4px;
  width: 310px;
}
.add-sprint-task textarea {
  padding: 16px;
}
.sprint-task-divider {
  margin-top: 16px;
  border-top: 1px solid #e4e5e7;
}
.scroll-sprints-div {
  width: 310px;
  max-height: 470px;
}
.sprint-hori-bar:hover {
  overflow-y: scroll;
}
.task-white-card {
  background: #ffffff;
  border-radius: 4px;
  padding: 16px;
  text-align: left;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}
.scroll-sprints-div .task-white-card:first-child {
  margin-top: 16px;
}
.task-assig-icons {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.task-assig-icons div img:last-child {
  margin-left: 16px;
}
.task-assig-icons img {
  cursor: pointer;
}
.task-assig-icons .profile-icon {
  margin-left: 16px;
}
/* Dialog CSS Started*/
#main-children {
  width: 447px;
  text-align: left;
}
.sprint-guide {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.workflow-stage {
  font-size: 12px;
  line-height: 140%;
  text-transform: uppercase;
  color: #979da5;
  margin-top: 24px;
}
.workflow-progress {
  margin-top: 16px;
  display: flex;
}
.stage-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-left: 4px;
  margin-top: 2px;
}
.arrow-connect {
  margin: 0px 11px;
}
/* Dialog CSS Ends*/

/* Sprint Progress Line */
.sprint-progress-div {
  margin: 16px 0px;
  padding: 22px 0px;
  border-top: 1px solid #54cf94;
  border-bottom: 1px solid #54cf94;
}
.completed-sprint-border {
  border-top: 1px solid #e4e5e7;
  border-bottom: 1px solid #e4e5e7;
}
.sprint-progress-wrap {
  background: #e4e5e7;
  border-radius: 4px;
  width: 279px;
  height: 4px;
  margin-top: 5px;
}
.flex-progress {
  display: flex;
  justify-content: space-between;
}
.sprint-progress-line {
  height: 100%;
  width: 20%;
  background: #0781f2;
  border-radius: 4px;
}
.completed-progress-line {
  width: 100%;
  background: #1b1e22;
}
.sprint-percent {
  font-size: 12px;
  line-height: 130%;
  color: #979da5;
}
.img-task-category {
  width: 32px;
  height: 32px;
}
.mark-as-completed-btn {
  background: #e4e5e7;
  border-radius: 4px;
  padding: 16px;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: #c8cbd0;
  margin-top: 16px;
  cursor: not-allowed;
}
.completed-mark {
  background: #54cf94;
  color: #ffffff;
  cursor: pointer;
}
</style>
<style>
.feature-card-div .v-expansion-panel__container {
  margin-top: 16px;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  width: 332px;
}
.feature-card-div .v-expansion-panel {
  -webkit-box-shadow: none;
}
.feature-card-div .v-expansion-panel__container:first-child {
  border-top: 1px solid #e4e5e7 !important;
}
.feature-card-div .v-expansion-panel__header {
  padding: 16px;
}
.feature-card-div
  .v-expansion-panel__container--active
  .v-expansion-panel__header {
  background: #f7f7f8;
}

/* Drag and drop Css */
.column-drag-handle {
  cursor: move;
  padding: 5px;
}

.card-ghost {
  transition: transform 0.18s ease;
  transform: rotateZ(5deg);
}

.card-ghost-drop {
  transition: transform 0.18s ease-in-out;
  transform: rotateZ(0deg);
}
/* Drag and CSS ends */
</style>
