var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "VuePerfectScrollbar",
        {
          staticClass: "perfect-scroll-area",
          attrs: { settings: _vm.settings },
        },
        [
          _c("div", { staticClass: "sprint-container" }, [
            _c(
              "div",
              { staticClass: "sprint-wrapper" },
              _vm._l(_vm.sprints, function (sprint, j) {
                return sprint.iteration_id == _vm.currentIteration.id
                  ? _c(
                      "div",
                      { key: j, staticClass: "sprint-card-div" },
                      [
                        _c("div", { staticClass: "sprint-info" }, [
                          _c("div", { staticClass: "sprint-name" }, [
                            _vm._v("Sprint " + _vm._s(sprint.sprint)),
                          ]),
                          _c("div", { staticStyle: { display: "flex" } }, [
                            _c("div", [
                              sprint.status < 1
                                ? _c("img", {
                                    attrs: {
                                      src:
                                        _vm.imgLink + "clock-not-started.svg",
                                    },
                                  })
                                : _vm._e(),
                              sprint.status == 1
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.imgLink + "clock-ongoing.svg",
                                    },
                                  })
                                : _vm._e(),
                              sprint.status == 2
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.imgLink + "clock-done.svg",
                                    },
                                  })
                                : _vm._e(),
                            ]),
                            sprint.start_date == null
                              ? _c("div", { staticClass: "sprint-due-date" }, [
                                  _vm._v(
                                    "\n                Not Started\n              "
                                  ),
                                ])
                              : _vm._e(),
                            sprint.status <= 1 && sprint.start_date != null
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "sprint-due-date",
                                    style:
                                      sprint.status == 1
                                        ? "color: #54CF94;"
                                        : "",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm
                                            .$moment(sprint.start_date)
                                            .format("Do MMM")
                                        ) +
                                        "-\n                " +
                                        _vm._s(
                                          _vm.addDaysFromDate(
                                            sprint.start_date,
                                            4
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            sprint.status == 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "sprint-due-date",
                                    staticStyle: { color: "#1b1e22" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                Completed\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                        sprint.status > 0
                          ? _c("div", [
                              sprint.status == 1
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "mark-as-completed-btn",
                                      class: {
                                        "completed-mark":
                                          _vm.percentageTasksDone(
                                            _vm.currentTasks
                                          ) == 100,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openMarkModal(sprint)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              Mark Sprint as Complete \n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass: "sprint-progress-div",
                                  class: {
                                    "completed-sprint-border":
                                      sprint.status == 2,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "flex-progress" }, [
                                    _c(
                                      "div",
                                      { staticClass: "sprint-progress-wrap" },
                                      [
                                        _c("div", {
                                          staticClass: "sprint-progress-line",
                                          class: {
                                            "completed-progress-line":
                                              sprint.status == 2,
                                          },
                                          style:
                                            sprint.status == 1
                                              ? "width:" +
                                                _vm.percentageTasksDone(
                                                  _vm.currentTasks
                                                ) +
                                                "%"
                                              : "",
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "sprint-percent" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              sprint.status == 1
                                                ? _vm.percentageTasksDone(
                                                    _vm.currentTasks
                                                  )
                                                : "100"
                                            ) +
                                            "%\n                "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ])
                          : _c("div", { staticClass: "sprint-task-divider" }),
                        _c(
                          "VuePerfectScrollbar",
                          {
                            staticClass: "perfect-scroll-area",
                            attrs: { settings: _vm.settings },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "scroll-sprints-div" },
                              _vm._l(_vm.tasks, function (task, k) {
                                return task.sprint_id == sprint.id
                                  ? _c(
                                      "div",
                                      {
                                        key: k,
                                        staticClass: "task-white-card",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDrawer(
                                              task,
                                              sprint
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(task.task) +
                                              "\n                "
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "task-assig-icons" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                task.feature_id == null
                                                  ? _c("img", {
                                                      attrs: {
                                                        src: "/img/dashboard/task/unlinked-task.svg",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                task.feature_id != null
                                                  ? _c("img", {
                                                      attrs: {
                                                        src: "/img/dashboard/task/link-task.svg",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                task.teams &&
                                                task.teams.length > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "profile-icon",
                                                      },
                                                      _vm._l(
                                                        _vm.teamMembers,
                                                        function (member, i) {
                                                          return member.id ==
                                                            task.teams[0].dev_id
                                                            ? _c("v-avatar", {
                                                                key: i,
                                                                staticClass:
                                                                  "profile_bg",
                                                                style: {
                                                                  backgroundImage:
                                                                    "url(" +
                                                                    (_vm.$imageUrl +
                                                                      member.profile_picture ||
                                                                      _vm.test) +
                                                                    ")",
                                                                },
                                                                attrs: {
                                                                  size: 32,
                                                                  color:
                                                                    "grey lighten-4",
                                                                },
                                                              })
                                                            : _vm._e()
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _c("img", {
                                                      attrs: {
                                                        src: "/img/dashboard/task/unassign-task.svg",
                                                      },
                                                    }),
                                              ]
                                            ),
                                            _c("div", [
                                              task.task_status_id == 5
                                                ? _c("img", {
                                                    attrs: {
                                                      src: "/img/sprint/task-completed.svg",
                                                    },
                                                  })
                                                : _c("div", [
                                                    task.teams &&
                                                    task.teams.length > 0
                                                      ? _c("img", {
                                                          staticClass:
                                                            "img-task-category",
                                                          attrs: {
                                                            src:
                                                              _vm.imgLink1 +
                                                              _vm.taskStage(
                                                                task.teams[0]
                                                                  .task_type_id
                                                              ) +
                                                              ".svg",
                                                          },
                                                        })
                                                      : _c("img", {
                                                          attrs: {
                                                            src: "/img/dashboard/task/task-status.svg",
                                                          },
                                                        }),
                                                  ]),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e()
                              }),
                              0
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e()
              }),
              0
            ),
          ]),
        ]
      ),
      _vm.isMarkAsCompleted
        ? _c(
            "MarkSprintAsCompleted",
            _vm._b(
              { attrs: { markAsCompleted: _vm.handleMarkAsCompleted } },
              "MarkSprintAsCompleted",
              { closeDialog: _vm.closeDialog, spinner: _vm.spinner },
              false
            )
          )
        : _vm._e(),
      _vm.isRatingModal
        ? _c(
            "RateYourTeam",
            _vm._b(
              {},
              "RateYourTeam",
              {
                closeDialog: _vm.closeDialog,
                sprintToMark: _vm.sprintToMark,
                handleMarkAsCompleted: _vm.handleMarkAsCompleted,
                spinner: _vm.spinner,
              },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }