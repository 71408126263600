<template>
  <div>
    <Toolbar stageTitle="Roadmap" />
    <Loader v-if="project == null" />
    <div v-else>
      <div class="page-container">
        <div class="itr-navigation">
          <div class="itr-toggle">
            <div
              @click="isIterationSprintToggle = false"
              class="iteration-btn"
              :class="{ 'active-btn': isIterationSprintToggle == false }"
            >
              Iterations
            </div>
            <div
              @click="isIterationSprintToggle = true"
              class="sprint-btn"
              :class="{ 'active-btn': isIterationSprintToggle == true }"
            >
              Sprints
            </div>
          </div>
          <div class="action-div">
            <div class="itr-time-line" v-if="!isIterationSprintToggle">
              <img src="/img/sprint/question-mark-circle.svg" alt="" />
              <span
                >Timeline:
                {{ iterations.length * project.project.iteration_cycle }}
                weeks</span
              >
            </div>
            <div v-if="isIterationSprintToggle" class="iteration-description">
              <img
                :src="`/img/dashboard/iteration${currentIteration.it_number}.svg`"
              />
              Iteration {{ currentIteration.it_number }} -
              <span> {{ `${currentIteration.name}’s` }} </span>
            </div>
            <div
              v-if="!project.project.accept_roadmap"
              class="share-roadmap-btn"
              @click="openDialog = true"
            >
              <img
                src="/img/dashboard/mark-icon.svg"
                style="margin-right: 8px"
              />
              Approve Roadmap
            </div>
          </div>
        </div>
        <div class="roadmap-wrapper">
          <div v-if="false" class="empty-roadmap">
            <div>
              <img src="/img/dashboard/empty-roadmap.svg" />
            </div>
            <div class="empty-state">There’s nothing here yet.</div>
            <div class="roadmapping-info">
              Start by putting the most important Product Features or User
              Stories in Iteration 1
            </div>
            <button class="grupa-blue-btn">Get Started</button>
          </div>

          <ClientSprint v-if="isIterationSprintToggle" />
          <ClientIteration v-else />
        </div>
      </div>
    </div>
    <DialogComponent
      dialogTitle="Approve Roadmap"
      v-if="openDialog"
      v-bind="{ closeDialog }"
    >
      <div id="main-children">
        <div class="share-roadmap-msg">
          You are about to confirm that this roadmap accurately captures your
          product idea, and that you agree with the product development path as
          outlined.
        </div>
        <div class="share-roadmap-prompt">Do you wish to continue?</div>
      </div>

      <template v-slot:dialog-footer>
        <div>
          <button
            @click="closeDialog"
            class="grupa-grey-btn"
            style="margin-right: 24px"
          >
            No, Go back
          </button>
          <button class="grupa-blue-btn" @click="hanldeAcceptRoadMap">
            Yes, Approve
            <img
              src="/img/lightbox/preloader.gif"
              style="margin-left: 20px"
              class="button-spinner"
              v-show="spinner"
            />
          </button>
        </div>
      </template>
    </DialogComponent>
    <AlertSuccess v-if="successMsg" time="7" />
    <AlertError v-if="errorMsg" time="7" />
  </div>
</template>

<script>
import DialogComponent from "@/components/Modals/dialogComponent";

import Loader from "@/components/general/centerLoader";
import ClientIteration from "./clientIteration";
import ClientSprint from "./clientSprint";
import { getClientProject } from "@/api/client";
import { clientAcceptRoadmap } from "@/api";
import AlertSuccess from "@/components/alertSuccess";
import AlertError from "@/components/alertError";
import Toolbar from "@/components/toolbar/generalToolBar";
import { mapActions, mapState } from "vuex";

export default {
  props: {},
  components: {
    ClientIteration,
    Toolbar,
    ClientSprint,
    Loader,
    DialogComponent,
    AlertSuccess,
    AlertError,
  },
  data: () => ({
    isIterationSprintToggle: false,
    project: null,
    openDialog: false,
    spinner: false,
  }),

  created() {
    this.getProject();
  },
  methods: {
    ...mapActions("clientDashboard", [
      "updateClientProjectDoc",
      "updateProject",
    ]),
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),

    closeDialog() {
      this.openDialog = false;
    },
    getProject() {
      let projectId = this.$route.params.id;
      getClientProject(projectId)
        .then((response) => {
          if (response.data.status == 1) {
            this.project = response.data;
            this.updateClientProjectDoc(this.project);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async hanldeAcceptRoadMap() {
      this.spinner = true;
      let project_id = this.$route.params.id;
      let obj = { project_id };
      await clientAcceptRoadmap(obj)
        .then((resp) => {
          this.spinner = false;
          this.openDialog = false;
          this.setSuccessMsg("Roadmap Approved!!");
          setTimeout(() => {
            this.$router.push({
              name: "project_workspace",
              params: { id: project_id },
            });
          }, 1000);
        })
        .catch((err) => {});
    },
  },
  computed: {
    ...mapState("alertMsg", ["successMsg", "errorMsg"]),
    ...mapState("clientDashboard", ["clientProjectDoc", "clientProject"]),
    projectObj() {
      return this.clientProject;
    },
    iterations() {
      return this.clientProjectDoc.documents.iterations;
    },
    currentIteration() {
      return this.clientProjectDoc.documents.iterations.find(
        (itr) => itr.id == this.projectObj.current_iteration
      );
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.page-container {
  padding: 0px;
  margin-top: 67px;
  min-height: calc(100vh - 67px);
  background: #f7f7f8;
}
.grupa-blue-btn {
  margin-top: 16px;
}
.share-roadmap-msg {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  width: 356px;
  text-align: left;
}
.share-roadmap-prompt {
  font-size: 14px;
  line-height: 130%;
  color: #6f7680;
  margin-top: 24px;
  text-align: left;
}
.itr-toggle {
  max-height: 40px;
}
.iteration-description {
  margin-right: 40px;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 8px;
}
.iteration-description span {
  color: #979da5;
}
</style>
