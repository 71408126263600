var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal-wrap" }, [
      _c("div", { staticClass: "dialog-header" }, [
        _c("div", { staticClass: "dialog-title" }, [
          _vm._v("Mark Sprint as Complete"),
        ]),
        _c(
          "div",
          { staticClass: "close-btn", on: { click: _vm.closeDialog } },
          [
            _c("img", {
              attrs: { src: "/img/onboarding/cancel_close.svg", alt: "" },
            }),
          ]
        ),
      ]),
      _vm._m(0),
      _c("div", { staticClass: "dialog-footer" }, [
        _c(
          "button",
          {
            staticClass: "grupa-grey-btn",
            staticStyle: { "margin-right": "24px" },
            on: { click: _vm.closeDialog },
          },
          [_vm._v("\n        Cancel\n      ")]
        ),
        _c(
          "button",
          {
            staticClass: "grupa-success-btn",
            on: { click: _vm.markAsCompleted },
          },
          [
            _vm._v("\n        Confirm & Pay Team\n        "),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.spinner,
                  expression: "spinner",
                },
              ],
              staticClass: "button-spinner",
              staticStyle: { "margin-left": "20px" },
              attrs: { src: "/img/lightbox/preloader.gif" },
            }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dialog-content" }, [
      _c("div", { staticClass: "mark-sprint-msg" }, [
        _vm._v(
          "\n        Confirm that all the tasks in this sprint have been completed.\n      "
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "mark-sprint-msg",
          staticStyle: { "margin-top": "16px" },
        },
        [
          _vm._v(
            "\n        We will proceed to pay the team for this sprint once you confirm.\n      "
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }