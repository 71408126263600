<template>
  <div class="feature-drawer">
    <v-navigation-drawer v-model="openDrawer" right absolute temporary>
      <div class="drawer-wrapper">
        <div class="toolbar">
          <div class="drawer-title">Product Feature</div>
        </div>
        <div class="drawer-content">
          <div class="drawer-left-box">
            <div class="drawer-top-content">
              <div class="drawer-feature-content">
                <div class="text-area-style">{{feature.feature}}</div>
                <div><h6 style="color:#979DA5">{{feature.description}}</h6></div>
              </div>
              <!-- <div class="drawer-add-desc">Add description</div> -->
            </div>
            <div class="iteration-progress">
              <div class="drawer-itr-name">
                <span>Iteration:</span>
                <img :src="`/img/dashboard/iteration1.svg`" />
                <span>Iteration 1</span>
              </div>
              <div class="progress-bar-div">
                <div class="progress-title">Progress:</div>
                <div class="progress-bar"></div>
                <div class="progress-percentage">0%</div>
              </div>
            </div>
            <div class="div-drawer-tasks">
              <div class="drawer-task-count">
                <img src="/img/dashboard/sprint-icon.svg" />
                <span>Tasks ({{tasks.length}})</span>
              </div>
              <div v-for="task in tasks" :key="task.id">
                  <div class="task-div">
                    {{task.task}}
                  </div>
              </div>
              
            </div>
            <div class="drawer-bottom-content">
              <div class="attachment-title">
                <div class="attach-file-count">
                  <img
                    src="/img/dashboard/attach.svg"
                    style="margin-right: 4px"
                  />
                  ATTACHMENTS ({{attachmentCount}})
                </div>
                <div class="attach-file">+ Attach File</div>
              </div>
              <div class="drawer-uploaded-doc">
                <div v-for="attachment in feature.attachments" :key="attachment.id" class="uploaded-image-cover">
                  <div
                    class="banner-position"
                    :style="{
                      backgroundImage: `url(${$imageUrl+attachment.file_name})`,
                    }"
                  ></div>
                </div>
                
              </div>
              <!-- <div class="feature-uploaded-files">
                <div style="display: flex">
                  <div class="file-icon">
                    <img src="/img/dashboard/file-icon.svg" />
                  </div>
                  <div class="file-long-name">
                    <div>
                      Title of the uploaded document that will be on one
                      line....pdf
                    </div>
                    <div><span>345.6kb</span></div>
                  </div>
                </div>
                <div class="file-view-icon">
                  <img src="/img/dashboard/expand-icon.svg" />
                </div>
              </div> -->
            </div>
          </div>
          <div class="drawer-right-box">
            <FeatureActivity />
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import FeatureActivity from "@/components/feature/featureActivity";
export default {
  props: {
    feature: Object,
    tasks: Array
  },
  components: {
    FeatureActivity,
  },

  data: () => ({
    openDrawer: false,
    featureContent: `While user stories or product features can be in multiple but not more than 2 lines on the main page, everything shows here`,
    image1: "/img/dashboard/dummy/image1.svg",
    image2: "/img/dashboard/dummy/image2.png",
  }),

  created() {
  },
  methods: {
    toggleDrawer() {
      this.openDrawer = !this.openDrawer;
    },
    
  },
  computed: {
    attachmentCount(){
      if(this.feature.attachments){
        return this.feature.attachments.length;
      }
      return 0;
    }
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.drawer-wrapper {
  width: 1039px;
  /* margin-top: 147px; */
}
.toolbar {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 64px;
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  right: 0px;
}

.drawer-title {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  padding: 22px 0px;
}
.btn-wrapper {
  margin: 10px 0px 9px 0px;
}
.task-div {
  padding: 10px;
  margin: 5px;
  background: #F7F7F8;
  border-radius: 4px;
}
</style>

