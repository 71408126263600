<template>
  <div class="modal-container">
    <div class="modal-wrap">
      <div class="dialog-header">
        <div class="dialog-title">
          Rate Team for Sprint {{ sprintToMark.sprint }}
        </div>
        <div class="close-btn" @click="closeDialog">
          <img src="/img/onboarding/cancel_close.svg" alt="" />
        </div>
      </div>
      <div class="dialog-content">
        <div class="dialog-wrapper">
          <div
            class="rating-question"
            style="margin-top: 0px; margin-bottom: 12px"
          >
            Tell us about your experience working with the team for this sprint.
          </div>
          <div
            class="question-box"
            v-for="(rating, index) in categories"
            :key="index"
          >
            <div class="question-header">{{ rating.name }}</div>
            <div class="rating-question">{{ rating.question }}</div>
            <div class="emoji-container">
              <div
                class="single-rating"
                v-for="(rate, i) in rating.ratingEmoji"
                :key="i"
              >
                <div
                  class="rate-icon"
                  @click="handleRateTeam(rating.name, rate, index)"
                >
                  <img
                    v-if="rating.value !== '' && rating.value == rate.selected"
                    :src="rating.value"
                  />
                  <img v-else :src="rate.img" />
                </div>
                {{ rate.name }}
                <!-- Emoji Name -->
              </div>
            </div>
            <div class="question-divider"></div>
          </div>
          <div class="form-grouped">
            <textarea
              class="form-control"
              name="comment"
              v-model="form.comment"
              rows="4"
              id="textarea"
              style="height: 80px"
              placeholder="Add any additional comments"
            ></textarea>
          </div>
        </div>
        <div class="dialog-footer">
          <button class="grupa-white-btn" @click="closeDialog">Cancel</button>
          <button
            style="margin-left: 8px"
            @click="handleRateAndMark"
            class="grupa-blue-btn"
          >
            Proceed
            <img
              src="/img/lightbox/preloader.gif"
              style="margin-left: 20px"
              class="button-spinner"
              v-show="spinner"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const ratings = [
  {
    name: "Abysmal",
    value: 1,
    img: "/img/rating/sad-icon.svg",
    selected: "/img/rating/sad-selected.svg",
  },
  {
    name: "Disappointing",
    value: 2,
    img: "/img/rating/boring-icon.svg",
    selected: "/img/rating/boring-selected.svg",
  },
  {
    name: "Meh",
    value: 3,
    img: "/img/rating/neutral-icon.svg",
    selected: "/img/rating/neutral-selected.svg",
  },
  {
    name: "Good enough",
    value: 4,
    img: "/img/rating/good-icon.svg",
    selected: "/img/rating/good-selected.svg",
  },
  {
    name: "Amazing",
    value: 5,
    img: "/img/rating/smiling-icon.svg",
    selected: "/img/rating/smiling-selected.svg",
  },
];
export default {
  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
    sprintToMark: {
      type: Object,
      required: true,
    },
    handleMarkAsCompleted: {
      type: Function,
      required: true,
    },
    spinner: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedRating: "/img/rating/smiling-selected.svg",
      categories: [
        {
          name: "professionalism",
          question: "How professional was the team?",
          value: "",
          ratingEmoji: JSON.parse(JSON.stringify(ratings)),
        },
        {
          name: "responsiveness",
          question: "How responsive was the team?",
          value: "",
          ratingEmoji: JSON.parse(JSON.stringify(ratings)),
        },
        {
          name: "empathy",
          question: "How well has the team been able to own the product?",
          value: "",
          ratingEmoji: JSON.parse(JSON.stringify(ratings)),
        },
        {
          name: "communication",
          question: "How well did the team keep you up-to-date?",
          value: "",
          ratingEmoji: JSON.parse(JSON.stringify(ratings)),
        },
      ],
      form: {
        professionalism: 0,
        responsiveness: 0,
        empathy: 0,
        communication: 0,
        comment: "",
      },
    };
  },
  created() {},
  methods: {
    handleRateTeam(data, emoji, index) {
      this.form[data] = emoji.value;
      this.categories[index].value = emoji.selected;
    },
    handleRateAndMark() {
      let payload = this.form;
      this.handleMarkAsCompleted(payload);
    },
  },
  computed: {},
};
</script>

<style scoped>
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-wrap {
  background: #ffffff;
  /* box-shadow: 2px 2px 20px 1px; */
  /* padding: 15px 0px 34px 0px; */
  flex-direction: column;
  min-width: 332px;
  /* min-height: 50vh; */
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.dialog-header {
  background: #f7f7f8;
  box-shadow: 0px 1px 2px rgba(30, 27, 34, 0.1);
  border-radius: 4px 4px 0px 0px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
.dialog-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  text-align: left;
  color: #1b1e22;
}
.dialog-content {
  padding: 24px;
  height: calc(100vh - 80px);
  overflow-y: scroll;
}
.dialog-wrapper {
  width: 356px;
  text-align: left;
}
.dialog-footer {
  padding: 0px 16px 16px 16px;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}
.close-btn {
  /* float: right; */
  cursor: pointer;
}
.small-modal {
  width: 404px;
}
.rating-question {
  margin-top: 12px;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.question-box {
  margin-top: 12px;
}
.question-header {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  text-transform: uppercase;
}
.emoji-container {
  display: flex;
  margin-top: 12px;
  justify-content: space-between;
}
.single-rating {
  text-align: center;
}
.rate-icon {
  margin-right: 8px;
}
.rate-icon img {
  width: 24px;
  height: 24px;
}
.question-divider {
  border-top: 1px solid #f7f7f8;
  margin-top: 12px;
}
.form-grouped {
  margin-top: 12px;
}
.form-control {
  margin-bottom: 0px;
}
</style>


