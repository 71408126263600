var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "VuePerfectScrollbar",
        {
          staticClass: "perfect-scroll-area",
          attrs: { settings: _vm.settings },
        },
        [
          _vm.iterations.length > 0
            ? _c(
                "div",
                { staticClass: "space-wrapper", attrs: { id: "it-cont" } },
                _vm._l(_vm.iterations, function (iteration, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "iteration-card" },
                    [
                      _c("div", { staticClass: "iteration-info" }, [
                        _c("div", { staticClass: "iteration-name" }, [
                          _c("img", {
                            attrs: {
                              src:
                                "/img/dashboard/iteration" +
                                (index + 1) +
                                ".svg",
                            },
                          }),
                          _vm._v(
                            "\n            Iteration " +
                              _vm._s(index + 1) +
                              " -\n            "
                          ),
                          _c("span", [_vm._v(_vm._s(iteration.name + "’s"))]),
                        ]),
                        _c("div", { staticClass: "iteration-duration" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.project.iteration_cycle) +
                              "wks\n          "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "divider-line" }),
                      _c(
                        "VuePerfectScrollbar",
                        {
                          staticClass: "perfect-scroll-area",
                          attrs: { settings: _vm.settings },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "features-wrapper" },
                            _vm._l(
                              _vm.iterations["features"],
                              function (feature, k) {
                                return feature.iteration_id == iteration.id
                                  ? _c(
                                      "div",
                                      { key: k, staticClass: "feature-card" },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(feature.feature)),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "feature-icons" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "feature-chat" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: "/img/dashboard/message-icon.svg",
                                                  },
                                                }),
                                                _vm._v(" 0\n                "),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "number-of-sprint",
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: "/img/dashboard/sprint-icon.svg",
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.returnTaskLenght(
                                                        feature.tasks
                                                      )
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "feature-upload" },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: "/img/dashboard/attach.svg",
                                                  },
                                                }),
                                                _vm._v(" 8\n                "),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e()
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
        ]
      ),
      _c("FeatureDrawer", {
        ref: "drawer",
        attrs: { tasks: _vm.featureTask, feature: _vm.featureDetails },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }