<template>
  <div class="feature-card" @click="handleDrawer">
    <div>{{ feature }}</div>
    <div class="feature-icons">
      <div class="feature-chat">
        <img src="/img/dashboard/message-icon.svg" /> {{ featureDetails.tasks }}
      </div>
      <div class="number-of-sprint">
        <img src="/img/dashboard/sprint-icon.svg" /> {{ featureDetails.chats }}
      </div>
      <div class="feature-upload">
        <img src="/img/dashboard/attach.svg" /> {{ featureDetails.files }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    feature: String,
    featureDetails: Object,
    handleDrawer: Function,
  },
  components: {},

  data: () => ({}),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.feature-upload, .feature-chat
{
  display: none;
}

</style>
