const appMixin = {
  data() {
    return {};
  },
  methods: {
    taskCategory(role) {
      if (role == "Product Designer") {
        return 2;
      } else if (role == "QA Engineer" || role == "QA/QC") {
        return 3;
      } else {
        return 1;
      }
    },
    taskStage(id) {
      if (id == 2) {
        return "design";
      } else if (id == 3) {
        return "testing";
      } else {
        return "development";
      }
    },
    taskType(id) {
      switch (id) {
        case 1:
          return "backlog";
        case 2:
          return "in-progress";
        case 3:
        case 4:
          return "completed";
        case 5:
          return "done";
      }
    },
    QATaskMGT(id) {
      if (id == 3) {
        return "backlog";
      } else if (id == 4) {
        return "in-progress";
      } else if (id == 5) {
        return "done";
      }
    },
  },
};

export default appMixin;
